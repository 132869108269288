import React, { useState } from 'react';
import Layout from '../layouts/layout';
import './blog.css'
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { Link } from 'gatsby';
import BlogCategory from '../components/blog-category';
import favicon from "../images/favico.ico";
import { Helmet } from 'react-helmet';



export default function BlogPage(props) {
    const [postsPerPage] = useState(6);


    const { data } = props.pageContext
    const { indice } = props.pageContext

    const postNumber = [];
    const posts = [];


    if (data) {

        for (let index = 1; index <= Math.ceil(data.length / postsPerPage); index++) {
            postNumber.push(index);
        }
        for (let index = (indice - 6); index < indice; index++) {
            if (data[index]) {
                posts.push(data[index]);
            }

        }
        console.log('pooooooooosts',posts.length)
    }


    const blogID = (props.location.pathname).match(/\d+/)[0]

    return (
        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>medica-tour.fr/blog/{blogID}/</title>
                <meta name="robots" content="index, follow" />
                <meta name="description" content={"Découvrez des articles et des conseils de qualité sur le tourisme médical et la santé à l'étranger sur le blog " + blogID+ " de Medica-Tour.Planifiez votre voyage en toute confiance "} />

                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr/blog/" + blogID + "/"}></link>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>
    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
    </script>
                     <script>
                        {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '8919602118131306');
                            fbq('track', 'PageView');
                        `}
                    </script>
</Helmet>    
    <noscript>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
                        height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
                    <img height="1" width="1" style={{ display: "none" }}
                        src="https://www.facebook.com/tr?id=8919602118131306&ev=PageView&noscript=1" />
                </noscript>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
height="0" width="0" style={{display:"none",visibility:"hidden"}} ></iframe></noscript>
            <div className="container-fluid service pt-5 pb-5">
                <div className="container intern-page-style">
                    <h1 className="service-title">
                        Blog
                    </h1>

                </div>
            </div>


            <div className="container pt-5">
                <div className="row">
                    <div className="col-sm-12 col-md-8 blogs">
                        <div class="row">
                            {posts && posts.map((item) => {
                                var today = new Date(item.date);
                                var dd = String(today.getDate()).padStart(2, '0');
                                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                var yyyy = today.getFullYear();
                                return (

                                    <div class="col-12 col-md-6 col-xl-4 mb-4">
                                        <div class="card mr-3">
                                            <Link className="btn " aria-current="page"
                                                to={item.uri}>
                                                <img style={{ height: '190px', width: '300px' }} src={item.featuredImage.sourceUrl} className="img-fluid rounded" alt={item.title} />
                                            </Link>
                                            <div class="card-body">
                                                <span class="card-text">
                                                    {dd + '/' + mm + '/' + yyyy}
                                                </span><br />
                                                <Link className="text-decoration-none" aria-current="page"
                                                    to={item.uri}>
                                                    <span class="card-title blog-title">{item.title}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div >
                    <div className="col-sm-12 col-md-4 ">
                        <BlogCategory />
                    </div>
                    
                </div >
            </div >

            <div className="container-fluid mt-5 mb-3 pb-5">
                <nav>
                    <ul className="pagination justify-content-center">

                        {
                            (parseInt(blogID) !== 1 && parseInt(blogID) !== 2) && <li className="page-item ">
                                <Link style={{ fontWeight: 'bold' }} className="page-link" to={'/blog/' + (parseInt(blogID) - 1) + '/'}><FaAngleLeft /></Link>
                            </li>
                        }



                        {
                            parseInt(blogID) === 1 && <li className="page-item ">
                                <Link style={{ fontWeight: 'bold' }} className="page-link" to={'/blog/'}><FaAngleLeft /></Link>
                            </li>
                        }

                        {
                            parseInt(blogID) === 2 && <li className="page-item ">
                                <Link style={{ fontWeight: 'bold' }} className="page-link" to={'/blog/'}><FaAngleLeft /></Link>
                            </li>
                        }

                        {postNumber.map((item) => {

                            if (item === 1) {
                                return <li key={item} className="active" >
                                    <Link style={{ fontWeight: 'bold' }} className="page-link activeLi" to={'/blog/'}>{item}</Link>
                                </li>
                            }


                            if (item === parseInt(blogID)) {
                                return <li key={item} className="active" >
                                    <Link style={{ color: 'black', fontWeight: 'bold' }} className="page-link" to={'/blog/'}>{item}</Link>
                                </li>
                            } else {
                                return <li key={item} >
                                    <Link style={{ fontWeight: 'bold' }} className="page-link" to={'/blog/' + item + '/'}>{item}</Link>
                                </li>
                            }
                        })
                        }


                        {
                            postNumber.length !== parseInt(blogID) && <li className="page-item ">
                                <Link style={{ fontWeight: 'bold' }} className="page-link" to={'/blog/' + (parseInt(blogID) + 1) + '/'}><FaAngleRight /></Link>
                            </li>
                        }



                    </ul>
                </nav>

            </div>


        </Layout >
    )
}