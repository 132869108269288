import * as React from "react";
import "./interne.css";
import Layout from "../layouts/layout";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import favicon from "../images/favico.ico";
import { StaticImage } from "gatsby-plugin-image"





const ChurigPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    const { faq } = props.pageContext

console.log("faaaaq",faq)
const children = [];

if(faq){
    if (faq.question1 != null) {
        children.push({ question: faq.question1, answer: faq.reponse1 })
    }
    if (faq.question2 != null) {
        children.push({ question: faq.question2, answer: faq.reponse2 })
    }
    if (faq.question3 != null) {
        children.push({ question: faq.question3, answer: faq.reponse3 })
    }
    if (faq.question4 != null) {
        children.push({ question: faq.question4, answer: faq.reponse4 })
    }
    if (faq.question5 != null) {
        children.push({ question: faq.question5, answer: faq.reponse5 })
    }
    if (faq.question6 != null) {
        children.push({ question: faq.question6, answer: faq.reponse6 })
    }
    if (faq.question7 != null) {
        children.push({ question: faq.question7, answer: faq.reponse7 })
    }
    if (faq.question8 != null) {
        children.push({ question: faq.question8, answer: faq.reponse8 })
    }
    if (faq.question9 != null) {
        children.push({ question: faq.question9, answer: faq.reponse9 })
    }
    if (faq.question10 != null) {
        children.push({ question: faq.question10, answer: faq.reponse10 })
    }
}
    return (
        <Layout>

            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <script type="application/ld+json">
                    {JSON.stringify(children)}</script>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>

    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
    </script>
                     <script>
                        {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '8919602118131306');
                            fbq('track', 'PageView');
                        `}
                    </script>
</Helmet>    
    <noscript>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
                        height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
                    <img height="1" width="1" style={{ display: "none" }}
                        src="https://www.facebook.com/tr?id=8919602118131306&ev=PageView&noscript=1" />
                </noscript>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
height="0" width="0" style={{display:"none",visibility:"hidden"}} ></iframe></noscript>



            <div className="container-fluid service1 pt-5 pb-5">
                <div className="container intern-page-style">
                    <h1 className="service-title">
                        {title}
                    </h1>
                </div>
            </div>

            <div className="container mt-4 mb-4">
                <div className="row">
                    { 
                        
                    }
                    {childs && childs.map((child, i) => {
                        if (child.title == "Ballon gastrique en Tunisie : Une Solution Simple pour Perdre du Poids") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                        <StaticImage src="../images/ballon-gastriqu.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }

                        if (child.title == "Abdominoplastie en Tunisie : Retrouver Confiance en Votre Corps") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/abdo-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mini Abdominoplastie en Tunisie : Une Solution Ciblée pour un Ventre Parfait") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/min-abdo-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Body Lift en Tunisie : Redéfinissez Votre Silhouette en Toute Confiance") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/body-lift-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting des Bras en Tunisie : Retrouver des Bras Fins et Raffermis") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lifting-bras-women-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting des Cuisses en Tunisie : Des Jambes Raffermies et Élégantes") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lifting-cuisse-2.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Augmentation des mollets Tunisie") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/aug-mollet-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Liposuccion en Tunisie : Affinez Votre Silhouette avec Medica Tour") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Liposuccion-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Augmentation des Fesses en Tunisie : Offrez-vous un Fessier Harmonieux et Rebondi") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/aug-fesses-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lipofilling Fessier en Tunisie : Offrez-vous un Galbe Naturel et Élégant") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lipolling-fesse-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "BBL en Tunisie : Le Lifting Brésilien pour un Fessier Harmonieux") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/bbl-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting des Fesses en Tunisie : Des Résultats Naturels et Durables") {
                            return (
                        
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
                        
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/by-pass.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
                        
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                              if (child.title == "Augmentation Mammaire en Tunisie : Offrez-vous la Poitrine de Vos Rêves") {
                                        return (
                        
                                            <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                                <div className="card h-100" >
                        
                                                    <Link to={child.uri} className="link-interv">
                                                        <StaticImage src="../images/augmentation-mammaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                                    </Link>
                                                    <div className="card-body card-body-intervention   w-100">
                                                        <Link to={child.uri} className="link-interv">
                                                            <span className="text-center card-body-title ">{child.title}</span>
                                                        </Link>
                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (child.title == "Lifting Mammaire en Tunisie : Redonnez Jeunesse et Fermeté à Votre Poitrine") {
                                        return (
                        
                                            <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                                <div className="card h-100" >
                        
                                                    <Link to={child.uri} className="link-interv">
                                                        <StaticImage src="../images/lifting-seins.webp" className="card-img-top img-fluid" alt={child.title} />
                                                    </Link>
                                                    <div className="card-body card-body-intervention   w-100">
                                                        <Link to={child.uri} className="link-interv">
                                                            <span className="text-center card-body-title ">{child.title}</span>
                                                        </Link>
                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (child.title == "Lipofilling Mammaire en Tunisie : Une Poitrine Naturelle et Raffinée") {
                                        return (
                        
                                            <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                                <div className="card h-100" >
                        
                                                    <Link to={child.uri} className="link-interv">
                                                        <StaticImage src="../images/lipofilling.webp" className="card-img-top img-fluid" alt={child.title} />
                                                    </Link>
                                                    <div className="card-body card-body-intervention   w-100">
                                                        <Link to={child.uri} className="link-interv">
                                                            <span className="text-center card-body-title ">{child.title}</span>
                                                        </Link>
                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (child.title == "Réduction Mammaire en Tunisie : Offrez vous Confort et Proportion") {
                                        return (
                        
                                            <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                                <div className="card h-100" >
                        
                                                    <Link to={child.uri} className="link-interv">
                                                        <StaticImage src="../images/reduction.webp" className="card-img-top img-fluid" alt={child.title} />
                                                    </Link>
                                                    <div className="card-body card-body-intervention   w-100">
                                                        <Link to={child.uri} className="link-interv">
                                                            <span className="text-center card-body-title ">{child.title}</span>
                                                        </Link>
                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                        if (child.title == "Bypass gastrique en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/by-pass.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Anneau gastrique en Tunisie : Une Solution Bariatrique Peu Invasive") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/anneau.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "L’opération de sleeve gastrique en Tunisie : Une Chirurgie Efficace contre l’Obésité Sévère") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/sleeve-gas1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Implant Dentaire en Tunisie : Retrouver Votre Sourire avec Confiance") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/implant.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Gynécomastie en Tunisie : Une Solution Discrète pour Retrouver Confiance") {
                            return (
            
                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >
            
                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Gynecomastie.jpg" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>
            
                                        </div>
                                    </div>
                                </div>
                            )
                        }
             if (child.title == "Blépharoplastie en Tunisie : Rajeunissez Votre Regard avec Medica Tour") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/Blepharoplastie-1.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Genioplastie en Tunisie : Sublimez Votre Profil avec Medica Tour") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/Genioplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Lifting Visage en Tunisie : Offrez-vous un Visage Jeune et Rajeuni") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/lifiting.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Mini Lifting Visage en Tunisie : Une Solution Discrète pour Rajeunir") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/mini-lifting.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Otoplastie en Tunisie : Redonnez Harmonie à Vos Oreilles avec Medica Tour") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/Otoplastie-.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Rhinoplastie en Tunisie : Remodeler et Harmoniser Votre Nez") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/Rhinoplastie-1.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Lipofilling Visage en Tunisie : Sublimez Votre Visage avec Medica Tour") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/lipofilling-visage-en-tunisie.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Canthopexie en Tunisie : Offrez-vous un Regard Sublimé et Élégant") {
                return (
            
                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >
            
                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/Canthopexie.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>
            
                            </div>
                        </div>
                    </div>
                )
            }
                        if (child.title == "Facettes dentaires en Tunisie : Améliorez Votre Sourire à Prix Abordable") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/facette.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Blanchiment Dentaire en Tunisie : Offrez-vous un Sourire Radieux à Petit Prix") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/blanch.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Gingivectomie Tunisie : Harmonisez Votre Sourire en Toute Confiance") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/gingivectomie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Couronne dentaire en Tunisie :  Redonnez Esthétique et Fonction à Votre Sourire") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/couronne-dentaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Biopsie testiculaire en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/testiculaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Fécondation in vitro en Tunisie (FIV)") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/vitro.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Insémination artificielle Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/artificielle.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Greffe de Poils et Cheveux en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/chev.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Greffe de Barbe en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/barbre.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Greffe et Implant de Sourcils en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/sourcil.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Liposuccion Vaser en Tunisie : Une Solution de Précision pour Sublimer Votre Silhouette") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lipo-vaser-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    
                        if (child.title == "Acide Hyaluronique") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/acide-hylaronique.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "La Toxine Botulique ou Bot-ox") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/botox.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Traitement Laser") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/trait-laser.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mesobotox ou Microbotox") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/mesobotox.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "PRP Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/PRP.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Rhinoplastie Médicale en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/rhinoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Les Techniques d’Amincissement") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/amincissement.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Fils Tenseurs") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/fils-tenseurs.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mésothérapie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/mesotherapie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                       
           
                        if (child.title == "Nymphoplastie en Tunisie : Réduction des lèvres vaginales") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/nymphoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Vaginoplastie Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/vaginoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Hyménoplastie en Tunisie : Restauration de l&rsquo;hymen") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/hymenoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Penoplastie en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/penoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Prothèse pénienne gonflable") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/prothese-penienne-gonflable.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Implant Pénien Semi-Rigide en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/implant-semi-rigidexx.png" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })



                    }

                </div>
            </div>
            {/* free devis block */}
            {/* <div className="container-fluid pb-3 pt-3 free-devis-bloc">
                <div className="container pt-4">

                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-6 text-center">
                            <p className="float-start text-devis-block pt-2">Demandez gratuitement votre devis</p>
                        </div>

                        <div className="col-sm-12 col-md-6 text-center">
                            <Link to="/devis/" class="d-none d-sm-none d-md-block float-end btn btn-devis-free" role="button">Devis gratuit</Link>
                            <a href="#" className="d-sm-block d-md-none btn  btn-devis-free" role="button" aria-pressed="true">DEVIS GRATUIT</a>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid  pb-5 blog-single">
                <div className="container ">
                    <div className="row">

                        {content && parse(content)}
                        {(() => {
                            const options = [];
                            if (faq.question1 != null) {
                                options.push(<h2>FAQ</h2>)
                            }
                            const options1 = [];

                            if (faq.question1 != null) {
                                options1.push(
                                    <div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                        <h3 itemprop="name">  {faq.question1}</h3>
                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                            <div itemprop="text">
                                                {faq.reponse1}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            if (faq.question2 != null) {

                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question2}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse2}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question3 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question3}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse3}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question4 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question4}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse4}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question5 != null) {

                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question5}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse5}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question6 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question6}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse6}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question7 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question7}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse7}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question8 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question8}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse8}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question9 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question9}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse9}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question10 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question10}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse10}
                                        </div>
                                    </div>
                                </div>)
                            }
                            return [options, options1];
                        })()}
                    </div>
                </div>
            </div>

        </Layout>
    )

}

export default ChurigPage
